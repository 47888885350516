import React from 'react'
import { Checkbox, NormalField } from 'reactstrap-form-fields'
import { Button as BootButton } from 'reactstrap'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'

import Button from './btn'

let Form = () => {

  return (
    <form name="contact" action="submitted" method="POST" data-netlify="true">
      <input type="hidden" name="form-name" value="contact" />
      <NormalField name="name" label="お名前" placeholder="name" />
      <NormalField name="email" label="メールアドレス" placeholder="email" />
      <NormalField name="phone" label="電話番号" placeholder="phone" />
      <NormalField name="content" label="お問い合わせ内容" type="textarea" placeholder="content" />
      <Card>
        <CardBody>
          <CardTitle tag="h5">個人情報の取り扱いについて</CardTitle>
          <CardText>
            当社は以下の通り個人情報保護方針を定め、取得した個人情報の適正な運用を行います。<br />
            <a href="/privacy" target="_blank"><BootButton type="button" color="info">個人情報保護方針を確認</BootButton></a><br />
            個人情報保護方針の内容にご同意いただける場合には下記項目にチェックを入れてください。
          </CardText>
          <Checkbox required name="accept" text="個人情報の取り扱いについて同意する" />
        </CardBody>
      </Card>
      <Button type="submit">送信</Button>
    </form>
  )
}

export default Form
